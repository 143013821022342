<template>
  <div class="app-container">
    <div class="main-body">
      <el-row class="call-title">
        <el-col :span="24">
          <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
          <span class="title">项目信息</span>
        </el-col>
      </el-row>
      <div ref="searchBar" class="form-cls">
        <el-form @heightChange="_getTableHeight()" size="small">
          <el-row type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="项目名称:">
                <el-input
                  v-model.trim="queryParams.name"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="项目简称:">
                <el-input
                  v-model.trim="queryParams.abbreviation"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="施工单位:">
                <el-input
                  v-model.trim="queryParams.constructProjedt"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="施工许可证:">
                <el-input
                  v-model.trim="queryParams.permitNumber"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="6">
              <el-form-item label="是否出土:">
                <el-select class="input-width-6" v-model="queryParams.dateDimFlag" clearable>
                  <el-option :value="1" label="当天有出土"></el-option>
                  <el-option :value="2" label="本周有出土"></el-option>
                  <el-option :value="3" label="本月有出土"></el-option>
                  <el-option :value="4" label="本季度有出土"></el-option>
                  <el-option :value="5" label="本年有出土"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="处置证是否有效:">
                <el-select class="input-width-6" v-model="queryParams.valid" clearable>
                  <el-option :value="0" label="过期"></el-option>
                  <el-option :value="1" label="有效"></el-option>
                  <el-option :value="2" label="3天内过期"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <el-button plain size="small" type="primary" icon="el-icon-search" @click="queryList"> 查询 </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- </search-bar> -->
      </div>
      <div class="main-body">
        <vxe-toolbar ref="xToolbar" custom style="margin-left: 10px; padding-right: 10px">
          <template #buttons>
            <el-upload
              :action="`${uploadUrl}?type=0`"
              :data="uploadData"
              :headers="uploadHeaders"
              :on-success="handleUploadSuccess"
              :on-error="handleUploadError"
              :show-file-list="false"
              :file-list="[]"
            >
              <el-button plain key="addFile" size="small" type="primary"> 新增导入 </el-button>
            </el-upload>

            <el-upload
              style="margin-left: 10px"
              :data="uploadData"
              :headers="uploadHeaders"
              :on-success="handleUploadSuccess"
              :on-error="handleUploadError"
              :action="`${uploadUrl}?type=1`"
              :show-file-list="false"
              :file-list="[]"
            >
              <el-button plain key="addUpdate" size="small" type="primary"> 导入更新 </el-button>
            </el-upload>
            <el-button plain style="margin-left: 10px" key="addFile1" size="small" @click="exportFileTemp" type="primary">下载导入模板</el-button>
            <el-button plain style="margin-left: 10px" key="addFile" size="small" @click="exportFile" type="primary"> 导出 </el-button>
          </template>
          <template #tools>
            <vxe-pager
              align="left"
              placement="top"
              :total="total"
              :current-page.sync="queryParams.currentPage"
              border
              :page-sizes="[100, 120, 150]"
              size="small"
              :page-size.sync="queryParams.pageSize"
              :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
              @page-change="queryList('0')"
            />
          </template>
        </vxe-toolbar>
      </div>
      <el-row class="form-cls button-cls">
        <el-col :span="24">
          <vxe-table
            id="dataListTable"
            ref="table"
            v-loading="listLoading"
            class="-column-demo"
            :export-config="{}"
            :data="dataList"
            fit
            :height="_tableHeight"
            element-loading-text="加载中"
            border
            stripe
            column-key
            resizable
            highlight-current-row
            :resizable-config="{ minWidth: 50 }"
            :custom-config="{ storage: true }"
            :scroll-y="{ gt: -1 }"
            show-overflow="tooltip"
            show-footer-overflow="tooltip"
            show-header-overflow="tooltip"
            tooltip-effect="dark"
            size="small"
            style="width: 100%"
          >
            <vxe-table-column type="seq" title="序号" width="50" align="center" fixed="left" />
            <vxe-table-column field="permitNumber" title="施工许可证" align="center" width="200" />
            <vxe-table-column field="name" title="项目名称" align="center" width="300" />
            <vxe-table-column field="abbreviation" title="项目简称" align="center" width="250" />
            <vxe-table-column field="projectLicenseBeginDate" title="处置证开始时间" align="center" width="200" />
            <vxe-table-column field="projectLicenseExpireDate" title="处置证到期时间" align="center" width="200" />
            <vxe-table-column field="carOutNum" title="当前出土车次" align="center" width="200" />
            <vxe-table-column field="longitude" title="经度" align="center" width="200" />
            <vxe-table-column field="latitude" title="纬度" align="center" width="200" />
            <vxe-table-column field="address" title="项目位置" align="center" width="300" />
            <vxe-table-column field="localStreet" title="属地街道" align="center" width="120" />
            <!--            <vxe-table-column field="scale" title="项目规模（平方米）" align="center" width="200" />-->
            <vxe-table-column field="totalWeightQuantity" title="总土方量（吨）" align="center" width="120" />
            <!--            <vxe-table-column field="remainEarthworkQuantity" title="剩余土方量（万方）" align="center" width="120" />-->
            <!--            <vxe-table-column field="__type" title="项目类别" align="center" width="120" />-->
            <vxe-table-column field="isKeyProject" title="项目类别（千项万亿、两重两新、省重点、市重点、区重点）" align="center" width="120" />
            <vxe-table-column field="buildProjedt" title="建设单位" align="center" width="200" />
            <vxe-table-column field="buildProjectContact" title="建设单位联系人" align="center" width="200" />
            <vxe-table-column field="buildProjectPhone" title="建设单位联系电话" align="center" width="200" />
            <vxe-table-column field="constructProjedt" title="施工单位" align="center" width="200" />
            <vxe-table-column field="constructProjectContact" title="施工单位联系人" align="center" width="120" />
            <vxe-table-column field="constructProjectPhone" title="施工单位联系电话" align="center" width="120" />
            <vxe-table-column field="openTime" title="开工日期" align="center" width="120" />
            <vxe-table-column field="endTime" title="计划竣工日期" align="center" width="120" />
            <vxe-table-column field="depth" title="基坑深度" align="center" width="120" />
            <vxe-table-column field="totalOrders" title="历史总工单" align="center" width="120" />
            <vxe-table-column field="__status" title="工地状态" align="center" width="120" />
            <vxe-table-column align="center" fixed="right" title="操作" width="160">
              <template v-slot="{ row }">
                <span style="color: deepskyblue; cursor: pointer" @click="handleWeilan(row)">围栏标注</span>
                <span style="color: deepskyblue; cursor: pointer; margin-left: 10px" @click="handleContacts(row)">联系人管理</span>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-col>
      </el-row>

      <!-- 围栏 -->
      <el-dialog
        @opened="startTrack"
        :center="true"
        :visible.sync="weilanVisible"
        :before-close="closeTrack"
        title="围栏标注"
        width="1290px"
        :destroy-on-close="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <el-row :gutter="24">
          <el-col :span="16">
            <div class="amap-section">
              <el-amap
                :plugin="plugin"
                :center="center"
                :amap-manager="amapManager"
                :zoom="zoom"
                :events="events"
                :resize-enable="true"
                class="map"
                defaultCursor="default"
              >
                <el-amap-search-box :search-option="searchOption" :on-search-result="onSearchResult" class="search-box"></el-amap-search-box>
              </el-amap>
            </div>
          </el-col>
          <el-col :span="8">
            <el-form :model="formData" :rules="formRules" ref="formData" label-width="92px">
              <el-form-item label="围栏名称：" prop="name">
                <el-input v-model="formData.name" size="small" class="input-width-16" />
              </el-form-item>
              <el-form-item label="触发类型：" prop="driveType">
                <el-checkbox-group v-model="formData.driveType">
                  <el-checkbox :label="1" name="driveType">驶入围栏</el-checkbox>
                  <el-checkbox :label="2" name="driveType">驶出围栏</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="中心经度：" prop="longitude">
                <el-input v-model="formData.longitude" size="small" class="input-width-16" @change="weilanDataUpdate" />
              </el-form-item>
              <el-form-item label="中心纬度：" prop="latitude">
                <el-input v-model="formData.latitude" size="small" class="input-width-16" @change="weilanDataUpdate" />
              </el-form-item>
              <!-- <el-form-item label="围栏半径：" prop="radius">
                <el-input v-model="formData.radius" size="small" class="input-width-16" @change="weilanDataUpdate">
                  <template slot="append">米</template>
                </el-input>
              </el-form-item> -->
              <el-form-item label="围栏类型：" prop="graphicType">
                <el-radio-group v-model="formData.graphicType" @change="update">
                  <el-radio :label="0" name="graphicType">圆形围栏</el-radio>
                  <el-radio :label="1" name="graphicType">矩形围栏</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item v-if="formData.graphicType === 1" label="辅助围栏：" prop="aiderPoly">
                <el-switch :disabled="formData.disabledAiderPoly" v-model="formData.aiderPoly" @change="drawAiderPolys"></el-switch>
              </el-form-item>

              <el-form-item label="是否启用：" prop="used">
                <el-radio-group v-model="formData.used" @change="updateUsed">
                  <el-radio :label="false" name="used">不启用</el-radio>
                  <el-radio :label="true" name="used">启用</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitData">保存</el-button>
                <el-button @click="cancelData">取 消</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-dialog>

      <!-- 联系人 -->
      <el-dialog title="联系人管理" width="1000px" :visible.sync="contactsVisible">
        <vxe-table
          :data="contactsDataList"
          ref="contactsTable"
          fit
          border
          stripe
          column-key
          resizable
          highlight-current-row
          show-overflow="tooltip"
          tooltip-effect="dark"
          :edit-config="{ trigger: 'manual', mode: 'row', showStatus: true }"
          size="small"
        >
          <vxe-table-column type="seq" title="序号" width="50" align="center" fixed="left" />
          <vxe-table-column field="name" title="姓名" :edit-render="{ name: 'input' }" align="center" width="120" />
          <vxe-table-column field="duty" title="职能" :edit-render="{ name: 'input' }" align="center" />
          <vxe-table-column field="phone" title="手机号" :edit-render="{ name: 'input' }" align="center" />
          <vxe-table-column field="notificationType" title="通知类型" :edit-render="{ name: 'select' }" width="320" align="center">
            <template #edit="{ row }">
              <vxe-select v-model="row.notificationType" transfer multiple>
                <vxe-option v-for="item in noticeOptions" :value="item.value" :label="item.label" :key="item.value"> {{ item.label }} </vxe-option>
              </vxe-select>
            </template>
          </vxe-table-column>
          <vxe-table-column align="center" title="操作" width="160">
            <template #header>
              <div @click="handleContactsAdd" class="handleContactsAdd">操作 <i class="vxe-icon-square-plus"></i></div>
            </template>
            <template v-slot="{ row }">
              <!-- <span style="color: deepskyblue; cursor: pointer" @click="handleContactsCancel(row)">取消</span> -->
              <span style="color: deepskyblue; cursor: pointer" @click="handleContactsEdit(row)">编辑</span>
              <span style="color: deepskyblue; cursor: pointer; margin-left: 10px" @click="handleContactsDel(row)">删除</span>
            </template>
          </vxe-table-column>
        </vxe-table>
        <template slot="footer">
          <el-button type="primary" @click="submitContacts">保存</el-button>
          <el-button @click="contactsVisible = false">取 消</el-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { AMapManager } from 'vue-amap'
import CalTableHeight from '@/mixins/calTableHeight'
import {
  baseHomeList as queryList,
  baseHomeListExport as queryListExport,
  baseHomeDownloadTemplate as downloadTemplate,
  getElectricFence,
  getElectricFenceEdit,
  contactList,
  contactListSave,
  contactListAdd,
  contactListDel,
  contactListEdit
} from '@/api/base'
import { getToken } from '@/utils/auth.js'
import downloadFile from '@/utils/downloadFile'
import { drawPolygon, drawCircle, getPoints, polySideOptions, aiderPolySideOptions } from '@/utils/drawPolygon'
const amapManager = new AMapManager()
const contactsRow = {
  id: '',
  duty: '', // 职能
  name: '', // name
  notificationType: '', // 通知类型
  phone: '' // 手机号
}
let markerMap = null
export default {
  data() {
    return {
      total: 0,
      uploadUrl: '/admin-gov/project/upload', // admin-gov
      uploadData: {},
      uploadHeaders: {
        Authorization: `Bearer ${getToken()}` // 让每个请求携带自定义token 请根据实际情况自行修改
      },
      queryParams: {
        // name: '国科大杭高院双浦校区项目（一期）',
        name: '',
        constructProjedt: '',
        abbreviation: '',
        permitNumber: '',
        valid: '',
        dateDimFlag: '',
        currentPage: 1,
        pageSize: 100
      },
      dataList: [],
      contactsDataList: [],
      contactsSelectRow: {},
      noticeOptions: [
        { label: '离线', value: '离线' },
        { label: '超时', value: '超时' },
        { label: '非审批', value: '非审批' },
        { label: '超速', value: '超速' }
      ],
      contactsVisible: false,
      listLoading: false,
      weilanVisible: false,
      /** 圆形围栏 */
      circleSide: null,
      circleSideEdit: null,
      /** 多边形围栏 */
      polySide: null,
      polySideEdit: null,
      /** 多边形辅助围栏 */
      aiderPolySide: null,
      aiderPolySideEdit: null,
      /** 存老的marker位置， 用于移动marker 同时移动多边形围栏 */
      oldMakerPosition: {
        lat: null,
        lng: null
      },
      // 表单
      formData: {
        dataId: '',
        name: '',
        driveType: [],
        radius: 100,
        latitude: '120.236713',
        longitude: '30.206855',
        aiderPoly: false,
        disabledAiderPoly: true,
        used: false
      },
      formRules: {
        name: [{ required: true, message: '请输入围栏名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择触发类型', trigger: 'blur' }],
        radius: [{ required: true, message: '请输入围栏半径', trigger: 'blur' }]
      },
      // 地图
      map: null,
      /** 圆形围栏跟矩形围栏的tool */
      mouseTool: null,
      /** 辅助围栏tool */
      aiderMouseTool: null,
      plugin: [
        'AMap.MapType', //  类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
        'AMap.CircleEditor', // 圆圈编辑器插件
        'AMap.PolyEditor', // 多边形编辑器插件
        'AMap.MouseTool', // 画多
        'AMap.OverView', // 地图鹰眼插件
        'AMap.ToolBar', //  地图工具条
        'AMap.PlaceSearch',
        'AMap.Autocomplete',
        'AMap.CitySearch',
        'AMap.Geolocation',
        'AMap.Geocoder'
      ],
      resizeEnable: false, //是否监控地图容器尺寸变化
      zoom: 16, // 设置初始化级别
      mapStyle: 'amap://styles/blue', // 使用的自
      center: [120.236713, 30.206855],
      amapManager,
      events: {
        init: map => {
          this.map = map
        }
      },
      searchOption: {
        city: '',
        citylimit: true
      }
    }
  },
  mixins: [CalTableHeight],
  async mounted() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联,否则列配置将失效
      this.$refs.table.connect(this.$refs.xToolbar)
    })
    this.$nextTick(() => {
      this._getTableHeight()
    })
    this.queryList()
  },
  methods: {
    handleUploadSuccess(response, file, fileList) {
      // 自定义的成功判断逻辑
      if (response.code === 200) {
        console.log('上传成功:', response)
        this.$message.success('文件上传成功')
      } else {
        // 如果自定义判断失败，手动调用 handleUploadError
        this.handleUploadError({ response }, file, fileList)
      }
    },
    handleUploadError(error, file, fileList) {
      // 处理上传失败的逻辑
      let errorMessage = '文件上传失败'
      if (error.response) {
        const { code, msg } = error.response
        errorMessage = `上传失败，错误码: ${code}, 错误信息: ${msg}`
      } else {
        errorMessage = '文件上传失败，网络错误或服务器异常'
      }
      console.error(errorMessage)
      this.$message.error(errorMessage)
    },

    exportFile() {
      const { queryParams } = this
      queryListExport({
        ...queryParams
      }).then(res => {
        downloadFile(res)
      })
    },
    exportFileTemp() {
      return downloadTemplate({}).then(res => {
        downloadFile(res)
      })
    },
    queryList() {
      this.listLoading = true
      return queryList(this.queryParams)
        .then(({ data }) => {
          this.dataList = data.records.map(ele => {
            return {
              ...ele,
              __type: ele.projectCategories, // ['工地'][ele.type] || ele.type,
              __status: ['已开工', '未开工'][ele.status] || ele.status
            }
          })
          this.total = data.total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    handleWeilan(row) {
      getElectricFence({ type: 0, dataId: row.id }).then(({ data }) => {
        const _data = data[0] || {}
        if (!_data.latitude && !_data.longitude) {
          this.$message.error('未获取到经纬度数据')
        }
        this.formData = {
          ...this.formData,
          ..._data,
          name: _data.name,
          dataId: row.id,
          radius: _data.r,
          latitude: _data.latitude || this.center[0],
          longitude: _data.longitude || this.center[1],
          driveType: []
          // driveIn: data.driveIn, // 驶入告警 0-不告警 1-告警
          // driveOut: data.driveOut // 驶出告警 0-不告警 1-告警
        }
        if (_data.driveIn === 1) {
          this.formData.driveType.push(1) // 驶入告警
        }
        if (_data.driveOut === 1) {
          this.formData.driveType.push(2) // 驶出告警
        }
        this.weilanVisible = true
      })
    },
    handleContacts(row) {
      contactList({ dataId: row.id }).then(({ data }) => {
        this.contactsVisible = true
        this.contactsSelectRow = row
        this.contactsDataList = (data || []).map((ele, index) => {
          return {
            ...contactsRow,
            ...ele,
            notificationType: ele.notificationType.split('|'),
            id: ele.id,
            __id: index
          }
        })
      })
    },
    handleContactsEdit(row) {
      const $table = this.$refs.contactsTable
      if ($table) {
        return $table.setEditRow(row)
      }
    },
    async handleContactsCancel() {
      const $table = this.$refs.contactsTable
      if ($table) {
        await $table.clearEdit()
      }
      // 还原数据
      // await $table.revertData(row)
    },
    isActiveStatus(row) {
      const $table = this.$refs.contactsTable
      if ($table) {
        return $table.isEditByRow(row)
      }
    },
    handleContactsAdd() {
      this.contactsDataList = [
        ...this.contactsDataList,
        {
          ...contactsRow,
          __type: 'newAdd'
        }
      ]
    },
    handleContactsDel(row) {
      contactListDel({ id: row.id }).then(data => {
        this.contactsDataList = this.contactsDataList.filter(ele => {
          return ele.__id !== row.__id
        })
      })
    },
    contactListSave() {
      const { id } = this.contactsSelectRow
      const list = this.contactsDataList.map(ele => {
        return {
          dataId: ele.dataId || id,
          duty: ele.duty,
          name: ele.name,
          notificationType: Array.isArray(ele.notificationType) ? ele.notificationType.join('|') : ele.notificationType,
          phone: ele.phone
        }
      })
      contactListSave({
        dataId: id,
        list
      }).then(() => {
        this.$message.success(`保存成功`)
      })
    },
    resetForm() {},
    cancelData() {
      this.formData.aiderPoly = false
      this.formData.disabledAiderPoly = true
      this.weilanVisible = false
    },
    submitData() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在保存...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.6)',
            target: document.getElementById('el-dialog')
          })
          const { driveType = [] } = this.formData
          const driveIn = driveType.includes(1) ? 1 : 0
          const driveOut = driveType.includes(2) ? 1 : 0
          const { radius, latitude, longitude, graphicType, disabledAiderPoly, aiderPoly } = this.formData

          let points
          let aiderPolySidePoints

          if (graphicType === 0 && this.circleSide) {
            const circleRadius = this.circleSide.getRadius()
            const circleCenter = this.circleSide.getCenter()

            points = JSON.stringify({ radius: circleRadius, center: circleCenter })
          }

          if (graphicType === 1 && this.polySide) {
            points = JSON.stringify(getPoints(this.polySide)?.map(t => ({ lon: t?.[0], lat: t?.[1] })))
          }

          if (graphicType === 1 && this.aiderPolySide) {
            aiderPolySidePoints = JSON.stringify(getPoints(this.aiderPolySide)?.map(t => ({ lon: t?.[0], lat: t?.[1] })))
          }

          getElectricFenceEdit({
            ...this.formData,
            driveIn,
            driveOut,
            r: parseFloat(radius),
            driveType: undefined,
            radius: undefined,
            disabledAiderPoly: undefined,
            aiderPoly: undefined,
            assistantPolygonRange: aiderPolySidePoints,
            polygonRange: points
          })
            .then(res => {
              if (res.code === 200) {
                this.weilanVisible = false
                this.$message.success('保存成功')
                this.resetForm()
                this.queryList()
              } else {
                this.$message.error(res.msg)
              }
            })
            .finally(() => {
              loading.close()
            })
        }
      })
    },
    update() {
      const { graphicType } = this.formData
      if (graphicType === 0) {
        this.drawCircles()
      } else {
        this.drawPolygons()
      }
    },

    updateUsed() {
      const { used } = this.formData
    },
    updatePosition(longitude, latitude, that) {
      if (this.circleSide) {
        this.circleSide.setCenter(new AMap.LngLat(longitude, latitude))
      }

      if (this.polySide) {
        const oldPosition = markerMap.getPosition()
        const newData = { lng: longitude - oldPosition?.lng, lat: latitude - oldPosition?.lat }
        const pointsPolyData = getPoints(this.polySide)
        this.polySide.setPath(pointsPolyData?.map(t => [t?.[0] + newData.lng, t?.[1] + newData.lat]))
      }

      if (this.aiderPolySide) {
        const oldPosition = markerMap.getPosition()
        const newData = { lng: longitude - oldPosition?.lng, lat: latitude - oldPosition?.lat }
        const pointsPolyData = getPoints(this.aiderPolySide)
        this.aiderPolySide.setPath(pointsPolyData?.map(t => [t?.[0] + newData.lng, t?.[1] + newData.lat]))
      }
    },
    weilanDataUpdate() {
      // 更新地图
      const { radius, latitude, longitude } = this.formData

      this.updatePosition(longitude, latitude)
      markerMap.setPosition(new AMap.LngLat(longitude, latitude))

      this.map.setCenter(new AMap.LngLat(longitude, latitude))
      this.map.setFitView()
    },
    submitContacts() {
      this.contactListSave()
    },
    closeTrack(done) {
      this.formData.aiderPoly = false
      this.formData.disabledAiderPoly = true
      done()
    },
    startTrack() {
      const map = amapManager.getMap()
      if (!map) {
        return
      }
      if (!this.map) {
        this.map = map
      }
      if (markerMap) {
        map?.remove([markerMap])
      }

      const { latitude: Lat, longitude: Lng, radius = 0, graphicType, polygonRange, assistantPolygonRange } = this.formData

      let initPolygonData
      let initAssistantPolygonRange

      try {
        initPolygonData = JSON.parse(polygonRange)
        initAssistantPolygonRange = JSON.parse(assistantPolygonRange)
      } catch (error) {
        //
      }

      if (graphicType === 0) {
        const initRadius = initPolygonData?.radius || radius

        if (initRadius) {
          this.circleSide = new AMap.Circle({
            map: map,
            ...polySideOptions,
            center: new AMap.LngLat(initPolygonData?.center?.lng || Lng, initPolygonData?.center?.lat || Lat),
            radius: initRadius
          })
          map.add([this.circleSide])

          this.circleSideEdit = new AMap.CircleEditor(map, this.circleSide)

          this.circleSideEdit.open()
        } else {
          this.drawCircles()
        }
      }

      if (graphicType === 1 && polygonRange) {
        // 初始化多边形围栏
        this.polySide = new AMap.Polygon({
          map: map,
          ...polySideOptions,
          path: initPolygonData?.map(t => [t.lon, t.lat])
        })

        map.add([this.polySide])

        this.polySideEdit = new AMap.PolyEditor(map, this.polySide)

        this.polySideEdit.open()
        this.formData.disabledAiderPoly = false
      }

      if (graphicType === 1 && !polygonRange) {
        this.drawPolygons()
        this.formData.disabledAiderPoly = false
      }

      /** 辅助围栏 */
      if (graphicType === 1 && assistantPolygonRange) {
        // 初始化多边形围栏
        this.aiderPolySide = new AMap.Polygon({
          map: map,
          ...aiderPolySideOptions,
          path: initAssistantPolygonRange?.map(t => [t.lon, t.lat])
        })

        this.formData.aiderPoly = true
        this.formData.disabledAiderPoly = false

        map.add([this.aiderPolySide])

        this.aiderPolySideEdit = new AMap.PolyEditor(map, this.aiderPolySide)

        this.aiderPolySideEdit.open()
      }

      const marker = new AMap.Marker({
        map: map,
        icon: new AMap.Icon({
          size: new AMap.Size(25, 34),
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          imageSize: new AMap.Size(135, 40),
          imageOffset: new AMap.Pixel(-95, -3)
        }),
        position: new AMap.LngLat(Lng, Lat),
        offset: new AMap.Pixel(-13, -30)
      })

      this.oldMakerPosition = {
        lng: Lng,
        lat: Lat
      }

      marker.set('draggable', true)

      let _that = this

      marker.on('dragend', function (event) {
        const { target } = event
        const newPosition = target?.getPosition()
        _that.updatePosition(newPosition.lng, newPosition.lat, _that.oldMakerPosition)
        _that.formData = {
          ..._that.formData,
          latitude: newPosition.lat,
          longitude: newPosition.lng
        }
        _that.oldMakerPosition = newPosition

        if (map) {
          map.setFitView()
        }
      })

      map.setCenter(new AMap.LngLat(Lng, Lat))
      markerMap = marker
      map.setFitView()
      map.add([marker])
    },
    drawCircles() {
      // 画圆形围栏
      const map = amapManager.getMap()

      this.formData.disabledAiderPoly = true
      this.formData.aiderPoly = false

      if (!this.map) {
        this.map = map
      }

      if (this.mouseTool) {
        this.mouseTool.close()
      }

      if (this.polySideEdit) {
        this.polySideEdit.close()
        this.polySideEdit = null
      }

      if (this.polySide) {
        this.map.remove([this.polySide])
        this.polySide = null
      }

      if (this.aiderMouseTool) {
        this.aiderMouseTool.close()
      }

      if (this.aiderPolySideEdit) {
        this.aiderPolySideEdit.close()
        this.aiderPolySideEdit = null
      }

      if (this.aiderPolySide) {
        this.map.remove([this.aiderPolySide])
        this.aiderPolySide = null
      }

      const mouseTool = new AMap.MouseTool(map)

      drawCircle(mouseTool)

      let _that = this

      mouseTool.on('draw', function (event) {
        mouseTool.close()

        const { obj } = event

        obj.set('draggable', true)

        _that.circleSideEdit = new AMap.CircleEditor(map, obj)
        _that.circleSideEdit.open()

        _that.circleSide = obj
      })

      this.mouseTool = mouseTool
    },
    drawPolygons() {
      // 画矩形围栏
      const map = amapManager.getMap()
      if (!this.map) {
        this.map = map
      }

      if (this.mouseTool) {
        this.mouseTool.close()
      }

      if (this.circleSideEdit) {
        this.circleSideEdit.close()
        this.circleSideEdit = null
      }

      if (this.circleSide) {
        this.map.remove([this.circleSide])
        this.circleSide = null
      }

      const mouseTool = new AMap.MouseTool(map)

      drawPolygon(mouseTool)

      let _that = this

      mouseTool.on('draw', function (event) {
        mouseTool.close()

        const { obj } = event

        obj.set('draggable', true)

        _that.polySideEdit = new AMap.PolyEditor(map, obj)
        _that.polySideEdit.open()

        _that.polySide = obj
        _that.formData.disabledAiderPoly = false
      })

      this.mouseTool = mouseTool
    },
    drawAiderPolys() {
      const { aiderPoly } = this.formData
      // 画辅助围栏
      const map = amapManager.getMap()
      if (!this.map) {
        this.map = map
      }

      if (this.aiderMouseTool) {
        this.aiderMouseTool.close()
      }

      let _that = this

      if (aiderPoly) {
        const aiderMouseTool = new AMap.MouseTool(map)

        drawPolygon(aiderMouseTool, true)

        aiderMouseTool.on('draw', function (event) {
          aiderMouseTool.close()

          const { obj } = event

          obj.set('draggable', true)

          _that.aiderPolySideEdit = new AMap.PolyEditor(map, obj)
          _that.aiderPolySideEdit.open()

          _that.aiderPolySide = obj
        })

        this.aiderMouseTool = aiderMouseTool
      } else {
        // 清除辅助围栏
        if (_that.aiderPolySideEdit) {
          _that.aiderPolySideEdit.close()
        }

        if (_that.aiderPolySide) {
          map.remove([_that.aiderPolySide])
        }
      }
    },
    onSearchResult(pois) {
      const point = pois?.[0]
      const { lat, lng } = point
      if (!lat || !lng) {
        return
      }

      this.updatePosition(lng, lat)

      markerMap?.setPosition(new AMap.LngLat(lng, lat))
      this.map.setCenter(new AMap.LngLat(lng, lat))
      this.map.setFitView()
      this.formData = {
        ...this.formData,
        latitude: lat,
        longitude: lng
      }
    }
  }
}
</script>

<style>
.vxe-select--panel.is--transfer {
  z-index: 9001 !important;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}

.icon-cls-green {
  color: #67c23a;
}

.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.amap-section {
  height: 600px;
}

.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}

.el-pagination {
  float: right;
}

.-column-demo .vxe-header--row .vxe-header--column.-ghost,
.-column-demo .vxe-header--row .vxe-header--column.-chosen {
  background-color: #dfecfb;
}

.vxe-table--footer .col-blue {
  background: #f2f5f0;
}

/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}

.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}

.write-color {
  border-color: #f0f0f0;
}

/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}

.input-width-7 {
  width: 70% !important;
}

.input-width-8 {
  width: 80% !important;
}

.input-width-16 {
  width: 300px;
}

.search-box {
  position: absolute !important;
  top: 8px !important;
  left: 24px !important;
  height: 32px !important;
  opacity: 0.8 !important;
}
</style>
